@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/font/Poppins-Regular.ttf') format('truetype');
  /* Ubah path sesuai dengan lokasi font Poppins di proyek Anda */
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html,
body {
  margin: 0;
  height: 100vh;
  min-height: 100vh;
  background-color: #f3f4f6;
  font-family: 'Poppins', sans-serif;
}

#root {
  @apply h-full;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
  z-index: 1000; /* Must be greater than #side-dragbar:z-index */
}

.render-support {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  -webkit-transform: translateZ(0) scale(1, 1);
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: rgb(43, 62, 230);

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px rgb(43, 62, 230), 0 0 5px rgb(43, 62, 230);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: rgb(43, 62, 230);
  border-left-color: rgb(43, 62, 230);
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-darker:hover {
  background-color: darkgray;
}

.rs-input {
  font-size: 12px !important;
}

.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 1200;
}

.costume-date-picker
  > div.rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-cleanable.rs-picker-block
  > div {
  border: 1px solid #d1d5db !important;
}

.costume-date-picker-errors
  > div.rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-cleanable.rs-picker-block
  > div {
  border: 1px solid #fca5a5 !important;
}

.rs-picker-popup.rs-picker-popup-date {
  z-index: 120;
}
